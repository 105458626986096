import { axiosClient, mutateGraphQL, queryGraphQL } from '@/data';
import { CommercesWrite } from '@/data/commerce/commerce.graphql';
import { CompanyRead } from '@/data/company.graphql';
import { EmployeesBatchWrite } from '@/data/management/employee.graphql';
import { AddressesRead, AddressesWrite, AddressWrite } from '@/graphql/address.graphql';
import { CategoriesWrite } from '@/graphql/category.graphql';
import { ClientsRead, ClientsWrite } from '@/graphql/client.graphql';
import { HouseAccountsRead, HouseAccountsWrite } from '@/graphql/houseAccount.graphql';
import { ItemsWrite } from '@/graphql/item.graphql';
import { MenusWrite } from '@/graphql/menu.graphql';
import { UomsWrite } from '@/graphql/uom.graphql';
import idPick from '@/helpers/idPick';
import { isStringANumber } from '@/pages/dashboard/commerce/components/utils';
import useUserInfo from '@/providers/auth/useUserInfo';
import {
	Address,
	Category,
	Client,
	CommerceType,
	Company,
	HouseAccount,
	Mutation,
	MutationAddressesWriteArgs,
	MutationAddressWriteArgs,
	MutationCategoriesWriteArgs,
	MutationClientsWriteArgs,
	MutationCommercesWriteArgs,
	MutationEmployeesBatchWriteArgs,
	MutationHouseAccountsWriteArgs,
	MutationItemsWriteArgs,
	MutationMenusWriteArgs,
	MutationUomsWriteArgs,
	QueryAddressesReadArgs,
	QueryCategoriesReadArgs,
	QueryHouseAccountsReadArgs,
	UomValidator,
} from '@/types/schema';
import { gql } from '@apollo/client';
import axios from 'axios';
import { addYears, endOfMonth } from 'date-fns';
import { isEmpty, isNumber, keyBy, mapValues, pick, toLower, toUpper, uniq } from 'lodash-es';
import { customAlphabet, nanoid } from 'nanoid';
import { useSnackbar } from 'notistack';
import { v4 as uuidv4, validate } from 'uuid';

type ImportFields = {
	headers: string[],
	headerValues: string[],
	setData: ( data: any ) => Promise<Mutation | void>
};

export type ImportTypes = 'item'
	| 'client'
	| 'estimate'
	| 'invoice'
	| 'vendor'
	| 'houseAccount'
	| 'houseAccountCards'
	| 'address'
	| 'price';

const nanoidHA = customAlphabet( '123456789', 5 );

export default function useImportFields( type: ImportTypes, clientId?: string ): ImportFields {
	const { staff } = useUserInfo();
	let headers: string[], headerValues: string[], setData: ( data: any ) => Promise<Mutation | void>;
	const { enqueueSnackbar } = useSnackbar();
	
	switch ( type ) {
		case 'item':
			headers = [
				'ID',
				'Name',
				'Description',
				'Category',
				'Unit',
				'Price',
				'Cost',
				'Code',
				'SKU',
				'VendorSKU',
				'Vendor',
				'Stock',
				'Taxable',
				'Inventory',
				'Type',
				'Unit Id',
			];
			headerValues = [
				'id',
				'itemName',
				'description',
				'categoryName',
				'uomName',
				'uomPrice',
				'uomCost',
				'uomCode',
				'uomSku',
				'uomVendorSku',
				'vendorId',
				'uomQuantity',
				'taxable',
				'isInventory',
				'type',
				'uomId',
			];
			setData = async ( importedItems ) => {
				
				const vendorIds = importedItems.map( ( item ) => item?.vendorId?.trim() || null ).filter( Boolean );
				
				// Case 1. Updating UOM with vendor ID
				if ( !isEmpty( vendorIds ) ) {
					const modifiedUoms = importedItems.map( ( item ) => ( {
						...item,
						uomSku  : item?.uomSku?.trim() || null,
						uomCode : item?.uomCode?.trim() || null,
						uomId   : item?.uomId?.trim() || null,
						vendorId: item?.vendorId?.trim() || null,
					} ) );
					
					const uomSkus = modifiedUoms.map( ( item ) => item?.uomSku ).filter( Boolean );
					const uomCodes = modifiedUoms.map( ( item ) => item?.uomCode ).filter( Boolean );
					const uomIds = modifiedUoms.map( ( item ) => item?.uomId ).filter( Boolean );
					
					if ( !isEmpty( uomSkus ) || !isEmpty( uomCodes ) || !isEmpty( uomIds ) ) {
						const { uomsRead } = await queryGraphQL( {
							query    : gql`
								query UomsRead_f824($options: FilterOptions) {
									uomsRead(options: $options) {
										items {
											id
											name
											sku
											menus {
												id
											}
										}
									}
								}
							`,
							variables: {
								options: {
									limit : 5000,
									filter: {
										$or: [
											!isEmpty( uomIds ) && { id: { $in: uomIds } },
											!isEmpty( uomSkus ) && { sku: { $in: uomSkus } },
											!isEmpty( uomCodes ) && { code: { $in: uomCodes } },
										].filter( Boolean ),
									},
								},
							},
						} );
						
						const uoms = uomsRead.items;
						const updatedUoms: UomValidator[] = uoms.map( ( uom ) => {
							const vendorId = modifiedUoms.find( ( item ) => item?.uomId === uom.id || item?.uomSku === uom.sku || item?.uomCode === uom.code )
								?.vendorId
								?.trim();
							let existingMenuIds = uom.menus ? uom.menus.map( ( menu ) => menu.id ) : [];
							if ( vendorId ) {
								existingMenuIds = uniq( [ ...existingMenuIds, vendorId ] );
							}
							return {
								id   : uom.id,
								name : uom.name,
								price: uom.price,
								menus: existingMenuIds,
							};
						} );
						await mutateGraphQL<MutationUomsWriteArgs>( {
							mutation : UomsWrite,
							variables: {
								inputs: updatedUoms,
							},
						} );
					}
					return;
				}
				
				// Case 2. Create new items
				const data = importedItems.map( ( item ) => ( {
					...item,
					id          : item?.id || nanoid(),
					categoryName: item?.categoryName?.trim() || null,
				} ) );
				const categoryNames: string[] = uniq( data.map( ( item ) => item.categoryName ).filter( Boolean ) );
				
				let dbCategoriesMap: Record<string, string> = {};
				if ( !isEmpty( categoryNames ) ) {
					const { categoriesRead }: {
						categoriesRead: { items: Pick<Category, 'id' | 'name'>[] }
					} = await queryGraphQL<QueryCategoriesReadArgs>( {
						query    : gql`
							query CategoriesRead_a1e9($options: FilterOptions) {
								categoriesRead(options: $options) {
									items {
										id
										name
									}
								}
							}
						`,
						variables: {
							options: { limit: categoryNames.length, filter: { name: { $in: categoryNames } } },
						},
					} );
					
					if ( !isEmpty( categoriesRead?.items ) ) {
						dbCategoriesMap = categoriesRead.items.reduce( ( obj, cat ) => ( {
							...obj,
							[ cat.name ]: cat.id,
						} ), {} );
					}
					
					const categoriesToCreate = categoryNames.filter( ( name ) => !dbCategoriesMap[ name ] );
					
					if ( !isEmpty( categoriesToCreate ) ) {
						const newCategories = categoriesToCreate.map( ( name ) => ( {
							name,
							id: uuidv4(),
						} ) );
						await mutateGraphQL<MutationCategoriesWriteArgs>( {
							mutation : CategoriesWrite,
							variables: {
								inputs: newCategories,
							},
						} );
						dbCategoriesMap = {
							...dbCategoriesMap,
							...newCategories.reduce( ( obj, cat ) => ( { ...obj, [ cat.name ]: cat.id } ), {} ),
						};
					}
				}
				
				return data.length && await mutateGraphQL<MutationItemsWriteArgs>( {
					mutation : ItemsWrite,
					variables: {
						inputs: data.map( ( item ) =>
							( {
								id         : validate( item.id ) ? item.id : undefined,
								name       : item?.itemName,
								description: item?.description,
								type       : item?.type || undefined,
								isInventory: [ 'true', 'yes', 'inventory' ].includes( item?.isInventory?.toLowerCase() ),
								taxable    : [ 'true', 'yes', 'taxable' ].includes( item?.taxable?.toLowerCase() ),
								categories : dbCategoriesMap[ item.categoryName ]
									? [ dbCategoriesMap[ item.categoryName ] ]
									: [],
								uoms       : [ {
									id       : item?.uomId || undefined,
									name     : item.uomName,
									price    : parseFloat( item.uomPrice?.replace( /[^\d.-]/g, '' ) ) || 0,
									cost     : parseFloat( item.uomCost?.replace( /[^\d.-]/g, '' ) ) || 0,
									sku      : item.uomSku,
									vendorSku: item.uomVendorSku,
									quantity : parseFloat( item.uomQuantity?.replace( /[^\d.-]/g, '' ) ) || 0,
									selected : true,
									sequence : 1,
									code     : item?.uomCode,
								} ],
							} ) ),
					},
				} );
			};
			break;
		case 'client':
			headers = [
				'ID',
				'CustomerID',
				'Contact',
				'Name',
				'Email',
				'AccountingEmails',
				'MID',
				'Phone',
				'Cell',
				'Note',
				'TaxExempt',
				'Balance Limit',
				'Account Number',
				'External ID',
				'Line1',
				'Line2',
				'City',
				'State',
				'Zipcode',
				'Country',
				'ShippingAddressLine1',
				'ShippingAddressLine2',
				'ShippingAddressCity',
				'ShippingAddressState',
				'ShippingAddressPostalCode',
				'ShippingAddressCountry',
			];
			headerValues = [
				'id',
				'customerId',
				'contact',
				'name',
				'email',
				'accountingEmails',
				'gateway.externalId',
				'phone',
				'cell',
				'metadata.clientPrivateNote',
				'metadata.exemptFromTax',
				'balanceLimit',
				'companyNumber',
				'externalId',
				'line1',
				'line2',
				'city',
				'state',
				'postalCode',
				'country',
				'shippingAddressLine1',
				'shippingAddressLine2',
				'shippingAddressCity',
				'shippingAddressState',
				'shippingAddressPostalCode',
				'shippingAddressCountry',
			];
			setData = async ( importedItems ) => importedItems.length && await mutateGraphQL<MutationClientsWriteArgs>( {
				mutation : ClientsWrite,
				variables: {
					inputs: importedItems.map( ( client ) => ( {
						id              : validate( client.id ) ? client.id : undefined,
						customerId      : client?.customerId,
						contact         : client.contact,
						name            : client.name,
						email           : client.email ? client.email.toLowerCase() : null,
						accountingEmails: client.accountingEmails ? toLower( client.accountingEmails )?.trim()
							.replace( /\s+/g, '' ) : null,
						phone           : client.phone ? client.phone : null,
						cell            : client.cell ? client.cell : null,
						balanceLimit    : isNumber( +client?.balanceLimit ) ? +client.balanceLimit : undefined,
						metadata        : {
							clientPrivateNote: client[ 'metadata.clientPrivateNote' ],
							exemptFromTax    : client[ 'metadata.exemptFromTax' ]?.toLowerCase() === 'true' || client[ 'metadata.exemptFromTax' ]?.toLowerCase() === 'yes',
						},
						externalId      : client.externalId ? client.externalId : null,
						gateway         : client[ 'gateway.externalId' ] ? client[ 'gateway.externalId' ] : null,
						addresses       : [ client.line1 && client.city && client.state && client.postalCode && client.country
							                    ? {
								line1     : client.line1,
								line2     : client.line2 || '',
								city      : client.city,
								state     : client.state,
								postalCode: client.postalCode,
								country   : client.country,
								type      : 'BILLING',
							} : null,
						                    client.shippingAddressLine1 && client.shippingAddressCity && client.shippingAddressState && client.shippingAddressPostalCode && client.shippingAddressCountry
							                    ? {
								                    line1     : client.shippingAddressLine1,
								                    line2     : client.shippingAddressLine2 || '',
								                    city      : client.shippingAddressCity,
								                    state     : client.shippingAddressState,
								                    postalCode: client.shippingAddressPostalCode,
								                    country   : client.shippingAddressCountry,
								                    type      : 'SHIPPING',
							                    }
							                    : null,
						].filter( Boolean ),
					} ) ),
				},
			} );
			break;
		case 'houseAccount':
			headers = [
				'Client ID',
				'House Account ID',
				'Name',
				'Contact',
				'Email',
				'Phone',
				'Member Id',
				'Number',
				'Note',
				'Active',
				'Line 1',
				'Line 2',
				'City',
				'State',
				'Zip',
				'Country',
				'Past Due',
			];
			headerValues = [
				'clientId',
				'houseAccountId',
				'name',
				'contact',
				'email',
				'phone',
				'memberId',
				'Number',
				'note',
				'active',
				'line1',
				'line2',
				'city',
				'state',
				'postalCode',
				'country',
				'pastDue',
			];
			setData = async ( data ) => {
				if ( isEmpty( data ) ) return;
				let company: Company = staff?.company;
				if ( !company ) {
					const { companyRead } = await queryGraphQL( {
						query: CompanyRead,
					} );
					company = companyRead;
				}
				const companyId = company?.id || staff?.company.id;
				const houseAccountInfo: Record<number, {
					clientId: string,
					oldHouseAccountId: string
				}> = {};
				let clients: Client[] = [];
				
				const houseAccountEmails = data.filter( ( houseAccount ) => !houseAccount?.clientId )
					.map( ( houseAccount ) => houseAccount.email ).filter( Boolean );
				
				if ( !isEmpty( houseAccountEmails ) ) {
					const { clientsRead } = await queryGraphQL( {
						query    : ClientsRead,
						variables: {
							options: {
								limit : houseAccountEmails.length,
								filter: { email: { $in: houseAccountEmails } },
							},
						},
					} );
					clients = clientsRead?.items || [];
				}
				
				for ( const index in data ) {
					const houseAccount = data[ index ];
					houseAccountInfo[ index ] = { clientId: '', oldHouseAccountId: houseAccount?.houseAccountId || '' };
					
					let clientId = houseAccount?.clientId;
					let oldHouseAccountId = houseAccount?.houseAccountId || null;
					
					if ( !clientId ) {
						// Attempt to find client by email
						if ( houseAccount.email ) {
							clientId = clients.find( ( client ) => client.email === houseAccount.email )?.id;
						}
						
						// If there is still no clientId, attempt to create new client
						if ( !clientId ) {
							try {
								const { data } = await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/management/createClient`, {
									email         : houseAccount.email,
									companyName   : houseAccount.name,
									contact       : houseAccount.contact,
									phone         : houseAccount.phone,
									address       : pick( houseAccount, [
										'line1',
										'line2',
										'city',
										'state',
										'postalCode',
										'country',
									] ),
									company       : companyId,
									method        : 'New HA Client',
									noClientFilter: true,
								} );
								clientId = data.clientWrite?.id;
							} catch ( e ) {
								console.log( e );
							}
						}
						
					}
					
					// attempt to find old house account by memberId and phone or email
					if ( !oldHouseAccountId ) {
						try {
							if ( houseAccount.memberId && houseAccount.phone ) {
								const { houseAccountsRead } = await queryGraphQL( {
									query    : HouseAccountsRead,
									variables: {
										options: {
											limit : 1,
											filter:
												{
													memberId: houseAccount.memberId,
													phone   : houseAccount.phone,
												},
										},
									},
								} );
								oldHouseAccountId = houseAccountsRead?.items?.[ 0 ]?.id;
							}
						} catch ( e ) {
							console.log( e );
						}
					}
					// Update houseAccountInfo with clientId and oldHouseAccountId
					houseAccountInfo[ index ] = {
						clientId,
						oldHouseAccountId: oldHouseAccountId,
					};
				}
				
				await mutateGraphQL<MutationHouseAccountsWriteArgs>( {
					mutation : HouseAccountsWrite,
					variables: {
						inputs: data.map( ( houseAccount, index ) => ( {
							id: validate( houseAccountInfo[ index ]?.oldHouseAccountId )
								? houseAccountInfo[ index ]?.oldHouseAccountId
								: undefined,
							...pick( houseAccount, [
								'name',
								'phone',
								'contact',
								'memberId',
								'note',
							] ) as any,
							pastDue  : parseFloat( houseAccount.pastDue || '0' ),
							client   : houseAccountInfo[ index ]?.clientId || null,
							active   : houseAccount.active?.length
								? toLower( houseAccount.active ) === 'yes'
								: true,
							staff    : staff?.id || null,
							email    : houseAccount.email ? toLower( houseAccount.email ) : null,
							addresses: houseAccount.line1 && houseAccount.city ? [
								idPick( houseAccount, [
									'line1',
									'line2',
									'city',
									'state',
									'postalCode',
									'zip',
									'country',
								] ),
							] : undefined,
						} ) ),
						
					},
				} );
				
			};
			break;
		case 'houseAccountCards':
			headers = [
				'ID',
				'Name',
				'Email',
				'Cell',
				'HouseAccount',
				'PersonalId',
				'Credit',
				'Daily',
				'Monthly',
				'Total',
				'SpentToDate',
				'Active',
				'Expires',
			];
			headerValues = [
				'id',
				'name',
				'email',
				'phone',
				'houseAccountId',
				'personalId',
				'credit',
				'daily',
				'monthly',
				'total',
				'spentToDate',
				'active',
				'expires',
			];
			setData = async ( data ) => {
				const employees = data.filter( ( employee ) => validate( employee.houseAccountId ) );
				if ( isEmpty( employees ) ) {
					enqueueSnackbar( 'No valid House Account id is provided', { variant: 'error' } );
					return;
				}
				;
				const employeeIds = employees.map( ( employee ) => employee.id ).filter( Boolean );
				let houseAccountsIDMap: Record<string, HouseAccount>;
				let employeesEmailMap: Record<string, Record<string, { id: string }>>;
				
				// if employee ids, then update the employees
				if ( !isEmpty( employeeIds ) ) {
					if ( employeeIds.find( ( id ) => !validate( id ) ) ) {
						enqueueSnackbar( 'One or more member id is incorrect', { variant: 'error' } );
						return;
					}
				} else {
					const haIds = uniq( employees.map( ( employee ) => employee.houseAccountId ) );
					
					const { houseAccountsRead } = await queryGraphQL<QueryHouseAccountsReadArgs>( {
						query    : gql`query HouseAccountsRead_c3d0($options: FilterOptions) {
							houseAccountsRead(options: $options) {
								items {
									id
									number
									employees {
										id
										email
									}
								}
								count
							}
						}`,
						variables: {
							options: {
								limit : haIds.length,
								filter: { id: { $in: haIds } },
							},
						},
					} );
					houseAccountsIDMap = keyBy( houseAccountsRead.items, 'id' );
					
					employeesEmailMap = mapValues( keyBy( houseAccountsRead.items, 'id' ), ( account ) =>
						keyBy( account.employees.filter( ( employee ) => !isEmpty( employee.email ) ), 'email' ),
					);
				}
				
				await mutateGraphQL<MutationEmployeesBatchWriteArgs>( {
					mutation : EmployeesBatchWrite,
					variables: {
						inputs: employees.map( ( employee ) => {
							const houseAccount = houseAccountsIDMap?.[ employee.houseAccountId ];
							const employeeId = employee.id || employeesEmailMap?.[ employee.houseAccountId ]?.[ employee.email ]?.id;
							return {
								id: employeeId || undefined,
								...pick( employee, [
									'name',
									'phone',
									'personalId',
								] ) as any,
								active      : employee.active ? toLower( employee.active ) === 'yes' : true,
								email       : employee.email ? toLower( employee.email ) : null,
								dailyLimit  : employee.daily ? +employee.daily : null,
								monthlyLimit: employee.monthly ? +employee.monthly : null,
								absorbValue : isStringANumber( employee.credit ) ? { value: +employee.credit } : undefined,
								totalLimit  : employee.total ? +employee.total : null,
								cardNumber  : employeeId ? undefined : `9000${houseAccount.number}${nanoidHA()}`,
								expiryDate  : employee.expires
									? endOfMonth( new Date( employee.expires ) )
									: addYears( endOfMonth( new Date ), 5 ),
								houseAccount: employee?.houseAccountId,
								
							};
						} ),
					},
				} );
			};
			break;
		case 'invoice':
		case 'estimate':
			headers = [
				'Number',
				'Location',
				'Staff',
				'Policy',
				'Type',
				'Name',
				'Description',
				'TaxPercent',
				'Taxable',
				'Price',
				'PaidTotal',
				'Status',
				'CreatedDate',
				'StandingDate',
				'ServiceDate',
				'DueDate',
				'Notes',
				'ClientName',
				'Contact',
				'Email',
				'Phone',
				'Cell',
				'ClientAddressLine1',
				'ClientAddressLine2',
				'ClientAddressCity',
				'ClientAddressState',
				'ClientAddressCountry',
				'ClientAddressPostalCode',
				'ShippingAddressLine1',
				'ShippingAddressLine2',
				'ShippingAddressCity',
				'ShippingAddressState',
				'ShippingAddressCountry',
				'ShippingAddressPostalCode',
			];
			headerValues = [
				'number',
				'companyLocation',
				'staff',
				'policy',
				'type',
				'name',
				'description',
				'taxPercent',
				'taxable',
				'price',
				'paidTotal',
				'status',
				'createdDate',
				'standingDate',
				'serviceDate',
				'dueDate',
				'notes',
				'clientName',
				'clientContact',
				'clientEmail',
				'clientPhone',
				'clientCell',
				'clientAddressLine1',
				'clientAddressLine2',
				'clientAddressCity',
				'clientAddressState',
				'clientAddressCountry',
				'clientAddressPostalCode',
				'shippingAddressLine1',
				'shippingAddressLine2',
				'shippingAddressCity',
				'shippingAddressState',
				'shippingAddressCountry',
				'shippingAddressPostalCode',
			];
			setData = async ( data ) => {
				if ( isEmpty( data ) ) return;
				let company: Company = staff?.company;
				
				if ( !company ) {
					const { companyRead } = await queryGraphQL( {
						query: CompanyRead,
					} );
					company = companyRead;
				}
				
				let invoicesInfo: Record<number, {
					clientId: string,
					clientAddressId: string,
					shippingAddressId: string
				}> = {};
				
				const clientInfo = new Map<string, string>();
				
				for ( const index in data ) {
					const invoice = data[ index ];
					invoicesInfo = {
						...invoicesInfo,
						[ index ]: { clientId: '', clientAddressId: '', shippingAddressId: '' },
					};
					
					const clientFilter = invoice.clientEmail || invoice.clientName || invoice.clientContact || invoice.clientCell || invoice.clientPhone;
					
					let clientId = clientInfo.get( clientFilter );
					if ( clientId ) {
						invoicesInfo = {
							...invoicesInfo,
							[ index ]: { ...invoicesInfo[ index ], clientId },
						};
					} else {
						if ( clientFilter ) {
							try {
								const { data } = await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/management/createClient`, {
									email      : invoice.clientEmail,
									companyName: invoice.clientName,
									contact    : invoice.clientContact,
									cell       : invoice.clientCell,
									phone      : invoice.clientPhone,
									company    : company?.id || staff?.company.id,
								} );
								clientId = data.clientWrite?.id;
								clientInfo.set( clientFilter, clientId );
								invoicesInfo = {
									...invoicesInfo,
									[ index ]: { ...invoicesInfo[ index ], clientId },
								};
								
							} catch ( e ) {
								console.log( e );
							}
						}
					}
					
					let clientAddress: Address, shippingAddress: Address;
					
					if ( clientId && invoice.clientAddressLine1 && invoice.clientAddressCity && invoice.clientAddressState && invoice.clientAddressCountry && invoice.clientAddressPostalCode ) {
						try {
							const { addressesRead } = await queryGraphQL<QueryAddressesReadArgs>( {
								query    : AddressesRead,
								variables: {
									clientId,
									options: {
										filter: {
											line1: invoice.clientAddressLine1,
											city : invoice.clientAddressCity,
										},
									},
								},
							} );
							
							if ( !isEmpty( addressesRead.items?.[ 0 ] ) ) {
								clientAddress = addressesRead.items?.[ 0 ];
							} else {
								const { addressWrite } = await mutateGraphQL<MutationAddressWriteArgs>( {
									mutation : AddressWrite,
									variables: {
										clientId,
										input: {
											client    : clientId,
											line1     : invoice.clientAddressLine1,
											line2     : invoice.clientAddressLine2 || '',
											city      : invoice.clientAddressCity,
											state     : invoice.clientAddressState,
											country   : invoice?.clientAddressCountry,
											postalCode: invoice.clientAddressPostalCode,
											type      : 'BILLING',
										},
									},
									
								} );
								clientAddress = addressWrite;
							}
							
							invoicesInfo = {
								...invoicesInfo,
								[ index ]: {
									...invoicesInfo[ index ],
									clientAddressId: clientAddress.id,
								},
							};
							
						} catch {
						}
						
					}
					if ( clientId && invoice.shippingAddressLine1 && invoice.shippingAddressCity && invoice.shippingAddressState && invoice.shippingAddressPostalCode && invoice.shippingAddressCountry ) {
						try {
							const { addressesRead } = await queryGraphQL<QueryAddressesReadArgs>( {
								query    : AddressesRead,
								variables: {
									clientId,
									options: {
										filter: {
											line1: invoice.shippingAddressLine1,
											city : invoice.shippingAddressCity,
										},
									},
								},
							} );
							shippingAddress = addressesRead.items?.[ 0 ];
							if ( isEmpty( addressesRead.items ) ) {
								const { addressWrite } = await mutateGraphQL<MutationAddressWriteArgs>( {
									mutation : AddressWrite,
									variables: {
										clientId,
										input: {
											client    : clientId,
											line1     : invoice.shippingAddressLine1,
											line2     : invoice.shippingAddressLine2 || '',
											city      : invoice.shippingAddressCity,
											country   : invoice?.shippingAddressCountry,
											state     : invoice.shippingAddressState,
											postalCode: invoice.shippingAddressPostalCode,
											type      : 'SHIPPING',
										},
									},
									
								} );
								shippingAddress = addressWrite;
							}
							invoicesInfo = {
								...invoicesInfo,
								[ index ]: { ...invoicesInfo[ index ], shippingAddressId: shippingAddress.id },
							};
							
						} catch {
						}
						
					}
					
				}
				await mutateGraphQL<MutationCommercesWriteArgs>( {
					mutation : CommercesWrite,
					variables: {
						inputs: data.map( ( invoice, index ) => ( {
							number         : invoice.number,
							createdAt      : invoice.createdDate
								? new Date( invoice.createdDate )
								: undefined,
							companyLocation: validate( invoice.companyLocation ) ? invoice.companyLocation : null,
							paidTotal      : +invoice.paidTotal ? +invoice.paidTotal : 0,
							serviceDate    : invoice.serviceDate
								? new Date( invoice.serviceDate )
								: null,
							standingDate   : invoice.standingDate
								? new Date( invoice.standingDate )
								: null,
							dueDate        : invoice.dueDate ? new Date( invoice.dueDate ) : null,
							paid           : invoice.status === 'PAID',
							viewed         : invoice.status === 'VIEWED',
							sent           : invoice.status === 'SENT',
							completed      : invoice.status === 'APPROVED',
							notes          : invoice.notes || '',
							staff          : validate( invoice.staff ) ? invoice.staff : null,
							policy         : validate( invoice.policy ) ? invoice.policy : null,
							type           : invoice.type as CommerceType || toUpper( type ) || 'INVOICE',
							lineItems      : invoice?.name ? [ {
								name       : invoice.name,
								description: invoice.description,
								quantity   : 1,
								tax        : invoice.taxable === 'TRUE' ? !isNaN( +invoice.taxPercent )
									? +invoice.taxPercent
									: 0 : 0,
								price      : !isNaN( +invoice.price ) ? +invoice.price : 0,
							} ] : [],
							client         : invoicesInfo[ index ].clientId || null,
							clientAddress  : invoicesInfo[ index ].clientAddressId || null,
							shippingAddress: invoicesInfo[ index ].shippingAddressId || null,
						} ) ),
					},
				} );
			};
			break;
		case 'vendor':
			headers = [
				'ID',
				'Contact',
				'Company',
				'Email',
				'Phone',
				'Line1',
				'Line2',
				'City',
				'State',
				'Zip',
				'Country',
				'Active',
			];
			headerValues = [
				'id',
				'vendorContact',
				'vendorName',
				'vendorEmail',
				'vendorPhone',
				'line1',
				'line2',
				'city',
				'state',
				'postalCode',
				'country',
				'active',
			];
			setData = async ( data ) => {
				data.length && await mutateGraphQL<MutationMenusWriteArgs>( {
					mutation : MenusWrite,
					variables: {
						inputs: data.map( ( menu ) => ( {
							...pick( menu, [ 'vendorContact', 'vendorName', 'vendorPhone' ] ),
							id         : validate( menu.id ) ? menu.id : undefined,
							vendorEmail: menu.vendorEmail ? toLower( menu.vendorEmail ) : null,
							active     : [ 'true', 'yes', 'active' ].includes( menu.active?.toLowerCase() ),
							addresses  : menu.line1 && menu.city ? [
								idPick(
									menu, [
										'line1',
										'line2',
										'city',
										'state',
										'postalCode',
										'zip',
										'country',
									] ),
							] : undefined,
						} ) ),
					},
				} );
			};
			break;
		case 'address':
			headers = [
				'ClientID',
				'AddressID',
				'AddressExternalID',
				'Phone',
				'Line1',
				'Line2',
				'City',
				'State',
				'Zipcode',
				'Country',
			];
			headerValues = [
				'clientId',
				'id',
				'externalId',
				'phone',
				'line1',
				'line2',
				'city',
				'state',
				'postalCode',
				'country',
			];
			setData = async ( data ) => {
				data.length && await mutateGraphQL<MutationAddressesWriteArgs>( {
					mutation : AddressesWrite,
					variables: {
						inputs: data.filter( ( address ) => validate( address.clientId ) ).map( ( address ) => ( {
							...idPick( address, [ 'line1', 'line2', 'city', 'state', 'postalCode', 'country', 'phone' ] ),
							externalId: address?.externalId || null,
							client    : address.clientId,
							metadata  : {},
						} ) ),
					},
				} );
			};
			break;
		case 'price':
			headers = [
				'External ID',
				'Name',
				'Price',
				'Client ID',
			];
			headerValues = [
				'externalId',
				'name',
				'price',
				'clientId',
			];
			setData = async ( priceData ) => {
				await axiosClient.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/importClientMsrp`, {
					data     : priceData,
					staffId  : staff.id,
					companyId: staff.company.id,
					clientId,
				} );
			};
			break;
		default:
			return null;
	}
	
	return {
		headers,
		headerValues,
		setData,
	};
}
